import {WG_USERS_LOADED,} from '../actions/types';


const initialState = {
  wgUsers: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case WG_USERS_LOADED:
      return {
        ...state,
        wgUsers: action.payload.reduce((obj, user) => {
          obj[user._id] = user
          return obj
        }, {})
      }
    default:
      return state;
  }
}
