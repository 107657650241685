import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { PersistGate } from 'redux-persist/integration/react'

import './assets/css/wg.css'
import './assets/css/bootstrap4-charming.css'
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {BrowserRouter} from "react-router-dom"
import {Provider} from "react-redux"
import {persistor, store} from "./store"
import {CookiesProvider} from "react-cookie"
import ToastWrapper from "./components/ToastWrapper"

ReactDOM.render(
  <CookiesProvider>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ToastWrapper>
          <App/>
          </ToastWrapper>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </CookiesProvider>,

  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
