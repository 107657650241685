import React, {Component} from 'react'
import {Badge, Button, Col, Form, Row} from "react-bootstrap"
import {connect} from "react-redux"
import {archiveTransaction, bookTransaction, loadTransactions} from "../actions/financeActions"
import PropTypes from "prop-types"
import {loadWgUsers} from "../actions/generalActions"
import ASWrapper from "./ASWrapper"
import DatePicker, {registerLocale} from "react-datepicker"
import en from 'date-fns/locale/en-GB'
import {pushToast} from "../actions/toastActions"
import ConfirmModal from "./modals/ConfirmModal"
import {showModal} from "../actions/modalActions"

registerLocale('en', en)

class Finance extends Component {

  constructor(props) {
    super(props)
    let filter = ''
    if (this.props.location && this.props.location.state && this.props.location.state.filter) {
      filter = this.props.location.state.filter
    } else if (this.props.filter) {
      filter = this.props.filter
    }
    this.state = {
      location: '',
      category: '',
      amount: '',
      owner: this.props.auth.user ? this.props.auth.user._id : '',
      comment: '',
      affected: null,
      date: new Date(),
      allTransactions: this.props.finance ? this.props.finance.transactions : [],
      activeTransactions: [],
      page: 0,
      beerSplit: false,
      splitAmount: '',
      splitComment: '',
      splitInvolved: [],
      buttonDisabled: true,
      filter,
      filterTags: []
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleMultiChange = this.handleMultiChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.refreshData = this.refreshData.bind(this)
    this.renderTransactions = this.renderTransactions.bind(this)
    this.toggleTransaction = this.toggleTransaction.bind(this)
  }

  componentDidMount() {
    this.refreshData()
    if (this.state.filter !== '') {
      this.filterTransactions()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.auth.isAuthenticated && (!this.props.general.wgUsers || !this.props.finance.transactions)) {
      this.refreshData()
    }

    if (prevProps.auth.user !== this.props.auth.user) {
      this.setState({
        owner: this.props.auth.user ? this.props.auth.user._id : ''
      })
      this.refreshData()
    }

    if (prevProps.general.wgUsers !== this.props.general.wgUsers && this.props.general.wgUsers) {
      this.resetUserCheckBoxes()
    }

    if (prevState.filter !== this.state.filter) {
      this.filterTransactions()
    }

    if (this.props.finance && prevProps.finance.transactions.length !== this.props.finance.transactions.length) {
      this.setState({
          allTransactions: this.props.finance.transactions
        },
        this.filterTransactions)
    }
  }

  refreshData() {
    this.props.loadTransactions()
    this.props.loadWgUsers()
  }

  resetUserCheckBoxes() {
    let uncheckedUsers = JSON.parse(JSON.stringify(this.props.general.wgUsers))
    for (const id of Object.keys(uncheckedUsers)) {
      uncheckedUsers[id].checked = false
    }
    let checkedUsers = JSON.parse(JSON.stringify(this.props.general.wgUsers))
    for (const id of Object.keys(checkedUsers)) {
      checkedUsers[id].checked = checkedUsers[id].name !== 'Alex Fenn' && checkedUsers[id].name !== 'Julian Felix Flury'  // TODO: Temporary until April
    }
    this.setState({
      affected: checkedUsers,
      splitInvolved: uncheckedUsers
    })
  }

  toggleTransaction(e) {
    const transactionNr = parseInt(e.target.dataset.value)
    if (this.state.activeTransactions.includes(transactionNr)) {
      this.setState({
        activeTransactions: this.state.activeTransactions.filter(value => {
          return value !== transactionNr
        })
      })
    } else {
      this.setState({
        activeTransactions: [...this.state.activeTransactions, transactionNr]
      })
    }
  }

  confirmDelete(transId) {
    this.props.showModal({
        open: true,
        title: 'Delete Transaction',
        body: 'Are you sure you want to delete this transaction?',
        closeModal: this.closeModal,
        yesFct: (id) => this.props.archiveTransaction(id),
        args: [transId]
      },
      'confirm'
    )
  }

  validateForm() {
    const {
      location, category, amount, date, affected, beerSplit,
      splitAmount, splitInvolved, buttonDisabled
    } = this.state
    const nAffected = Object.values(affected).filter(user => user.checked).length
    const nSplitInvolved = Object.values(splitInvolved).filter(user => user.checked).length
    const splitCorrect = (beerSplit && splitAmount > 0 && parseFloat(splitAmount) <= parseFloat(amount) && nSplitInvolved > 0) || !beerSplit
    if (amount > 0 && date && location !== '' && category !== '' && nAffected > 0 && splitCorrect) {
      if (buttonDisabled) {
        this.setState({
          buttonDisabled: false
        })
      }
    } else if (!buttonDisabled) {
      this.setState({
        buttonDisabled: true
      })
    }
  }

  handleDateChange = date => {
    this.setState({
        date: date
      },
      this.validateForm
    )

  }

  handleASChange = (name, value) => {
    this.setState({
        [name]: value
      },
      this.validateForm
    )
  }

  handleChange(e) {
    this.setState({
        [e.target.name]: e.target.value
      },
      this.validateForm
    )
  }

  handleMultiChange(e) {
    this.setState({
        [e.target.name]: {
          ...this.state[e.target.name],
          [e.target.dataset.id]: {
            ...this.state[e.target.name][e.target.dataset.id],
            checked: e.target.checked
          }
        }
      },
      this.validateForm
    )
  }

  handleSubmit(e) {
    e.preventDefault()

    let {
      owner, location, category, amount, comment, date, affected, beerSplit,
      splitAmount, splitInvolved, splitComment
    } = this.state

    affected = Object.values(affected)
      .filter(user => user.checked)
      .map(user => user._id)

    if (affected.length === 0) {
      this.props.pushToast("Transaction Error", "There needs to be at least one involved person.")
      return
    }

    splitInvolved = Object.values(splitInvolved)
      .filter(user => user.checked)
      .map(user => user._id)

    splitAmount = splitAmount === '' ? 0 : parseFloat(splitAmount)
    amount = parseFloat(amount)
    // ignore split if box is closed
    if (!beerSplit) {
      splitAmount = 0
      splitInvolved = []
      splitComment = ''
    }

    this.props.bookTransaction(
      owner,
      amount,
      location,
      category,
      affected,
      comment,
      new Date(date),
      splitAmount,
      splitInvolved,
      splitComment
    )

    this.setState({
      owner: this.props.auth.user ? this.props.auth.user._id : '',
      location: '',
      category: '',
      amount: '',
      comment: '',
      date: new Date(),
      beerSplit: false,
      splitAmount: '',
      splitComment: '',
      buttonDisabled: true,
      allTransactions: this.props.finance ? this.props.finance.transactions : [],
    })

    this.resetUserCheckBoxes()
  }

  renderForm() {
    const {wgUsers} = this.props.general
    const {user} = this.props.auth
    if (!wgUsers || !user || !this.state.affected)
      return null
    return (
      <>
        <Form onSubmit={this.handleSubmit} noValidate>
          <Form.Group controlId="owner">
            <Form.Label column={0}>Paid by</Form.Label>
            <Form.Control name="owner" as="select" value={this.state.owner} onChange={this.handleChange}>
              {Object.values(wgUsers).map((wgUser, index) => {
                return <option key={index} value={wgUser._id}>{wgUser.name}</option>
              })}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label column={0}>Location / Shop</Form.Label>
            <ASWrapper
              ref={this.locationRef}
              suggestions={this.props.finance.locations}
              name="location"
              fieldValue={this.state.location}
              onChange={this.handleASChange}
              placeholder={"Where did the transaction occur?"}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label column={0}>Category</Form.Label>
            <ASWrapper
              ref={this.categoryRef}
              suggestions={this.props.finance.categories}
              name="category"
              fieldValue={this.state.category}
              onChange={this.handleASChange}
              placeholder={"What category does the transaction fall under?"}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label column={0}>Amount / Price</Form.Label>
            <Form.Control type="number" step="0.01" name="amount" placeholder="In CHF"
                          value={this.state.amount} onChange={this.handleChange} required={true}/>
          </Form.Group>
          <Form.Group>
            <Form.Label column={0}>Date</Form.Label>
            <br/>
            <DatePicker
              showPopperArrow={false}
              selected={this.state.date}
              onChange={this.handleDateChange}
              locale="en"
              dateFormat="dd.MM.yyyy"
              className="form-control"
              todayButton="Today"
              required={true}
            />
          </Form.Group>
          <Form.Group controlId="controlComment">
            <Form.Label column={0}>Comment (optional)</Form.Label>
            <Form.Control as="textarea" rows="3" name="comment"
                          value={this.state.comment} onChange={this.handleChange}/>
          </Form.Group>
          <Form.Group>
            <Form.Label column={0}>Involved People</Form.Label>
            {Object.values(wgUsers).map((wgUser, index) => {
              return <div key={`default-${wgUser._id}`} className="">
                <Form.Check
                  name="affected"
                  type={'checkbox'}
                  id={"aff_" + wgUser._id}
                  data-id={wgUser._id}
                  label={`${wgUser.name}`}
                  checked={this.state.affected[wgUser._id].checked}
                  onChange={this.handleMultiChange}
                />
              </div>
            })
            }
          </Form.Group>
          <Form.Group>
            <div className={"card hint-card"}>
              <div onClick={() => {
                this.setState({beerSplit: !this.state.beerSplit}, this.validateForm)
              }} className="card-header hint-header cursor-pointer">
                Beer Split?
              </div>
              <div id="collapseOne"
                   className={this.state.beerSplit ? "collapse show" : "collapse"}
                   data-parent="#accordion">
                <div className={"card-body hint-body"}>
                  <Form.Group>
                    <Form.Label column={0}>This part of the total amount</Form.Label>
                    <Form.Control type="number" step="0.01" name="splitAmount" placeholder="CHF to split off"
                                  value={this.state.splitAmount} onChange={this.handleChange} required={false}/>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label column={0}>Will be split between</Form.Label>
                    {Object.values(wgUsers).map((wgUser, index) => {
                      return <div key={`beer-${wgUser._id}`} className="">
                        <Form.Check
                          name="splitInvolved"
                          type={'checkbox'}
                          id={"split_" + wgUser._id}
                          data-id={wgUser._id}
                          label={wgUser.name}
                          checked={this.state.splitInvolved[wgUser._id].checked}
                          onChange={this.handleMultiChange}
                        />
                      </div>
                    })}
                  </Form.Group>
                  <Form.Group controlId="controlComment">
                    <Form.Label column={0}>With this optional comment</Form.Label>
                    <Form.Control as="textarea" rows="2" name="splitComment"
                                  value={this.state.splitComment} onChange={this.handleChange}/>
                  </Form.Group>
                </div>
              </div>
            </div>
          </Form.Group>
          <Form.Group className="text-center">
            <Button type="submit" disabled={this.state.buttonDisabled}>
              {this.state.buttonDisabled ? '...please fill all required fields...' : 'Add Transaction'}
            </Button>
          </Form.Group>
        </Form>
      </>
    )
  }

  static displayDate(date) {
    date = new Date(date)
    return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
  }

  renderTransactions() {
    const {user} = this.props.auth
    if (!user) {
      return null
    }
    let transactions = this.state.allTransactions
    const per_page = 10
    const total = transactions.length
    const pages = total / per_page
    transactions = transactions.slice(this.state.page * per_page, (this.state.page + 1) * per_page)
    const transOnPage = transactions.map((trans, index) => {

      const involved = trans.affected_users.filter(au => au._id === user._id).length > 0
      let hintCardClass = 'hint-card'
      let hintBodyClass = 'hint-body'
      if (!involved) {
        hintCardClass = 'hint-card-disabled'
        hintBodyClass = 'hint-body-disabled'
      }

      return (<div key={"transaction_" + index} className={"card " + hintCardClass}>
        <div onClick={this.toggleTransaction} data-value={index + 1} className="card-header hint-header cursor-pointer">
          {trans.category + " (" + trans.location + "): " + trans.amount.toFixed(2) + " CHF"}
          {trans.owner._id === user._id
            ? <i className="float-right align-middle fa fa-trash-alt"
                 style={{fontSize: "1.3em"}}
                 onClick={() => this.confirmDelete(trans._id)}
            /> : ''}
        </div>
        <div id="collapseOne"
             className={this.state.activeTransactions.includes(index + 1) ? "collapse show" : "collapse"}
             data-parent="#accordion">
          <div className={"card-body " + hintBodyClass}>
            {involved ? '' :
              <span style={{fontWeight: 'bold', fontStyle: 'italic'}}>
                You are not involved in this transaction!<br/>
              </span>}
            Paid by: {trans.owner.name}<br/>
            {trans.creator && trans.creator._id !== trans.owner._id
              ? ['Entered by: ', trans.creator.name, <br key="1"/>] : ''}
            Location: {trans.location}<br/>
            Category: {trans.category}<br/>
            Amount: {trans.amount.toFixed(2)} CHF<br/>
            Date: {Finance.displayDate(trans.date_of_transaction)}<br/>
            Recorded: {Finance.displayDate(trans.date_of_entry)}<br/>
            Involved: {trans.affected_users.map(au => au.name).join(", ")}
            {trans.comment !== ''
              ? <><br/>Comment: {trans.comment.split('\n').map((item, i) => {
                return [<br key={i}/>, item]
              })}</>
              : ''}
          </div>
        </div>
      </div>)
    })

    const curP = this.state.page
    return (
      <>
        {pages > 1
          ?
          <div className="text-center font-weight-bold" style={{fontSize: '2em'}}>
            <p>
              {curP > 0
                ? <><i onClick={() => this.setState({page: 0, activeTransactions: []})}
                       className="fa fa-angle-double-left cursor-pointer"/>&nbsp;&nbsp;
                  <i onClick={() => this.setState({page: this.state.page - 1, activeTransactions: []})}
                     className="fa fa-angle-left cursor-pointer"/></>
                : <span style={{color: '#777'}}><i className="fa fa-angle-double-left"/>&nbsp;&nbsp;<i
                  className="fa fa-angle-left"/></span>}
              &nbsp;&nbsp;
              {curP < Math.ceil(pages) - 1
                ? <><i onClick={() => this.setState({page: this.state.page + 1, activeTransactions: []})}
                       className="fa fa-angle-right cursor-pointer"/>&nbsp;&nbsp;
                  <i onClick={() => this.setState({page: Math.ceil(pages) - 1, activeTransactions: []})}
                     className="fa fa-angle-double-right cursor-pointer"/></>
                : <span style={{color: '#777'}}><i className="fa fa-angle-double-right"/>&nbsp;&nbsp;<i
                  className="fa fa-angle-right"/></span>}
            </p>
          </div>
          : ''
        }
        {
          <>
            <Form onSubmit={e => e.preventDefault()} noValidate>
              <Form.Group>
                <Form.Control type="text" name="filter" placeholder="Filter transactions"
                              value={this.state.filter} onChange={this.handleChange}/>
                {this.renderTags()}
              </Form.Group>
            </Form>
          </>
        }
        <ConfirmModal/>
        {transOnPage}
      </>
    )

  }

  renderTags() {
    const {filterTags} = this.state
    return filterTags.map((tag, idx) => {
      return <Badge pill variant="success" key={idx} style={{textTransform: 'capitalize'}}>{tag}</Badge>
    })
  }

  filterTransactions() {
    const {user} = this.props.auth
    const {wgUsers} = this.props.general
    const wgNames = Object.values(wgUsers).map(u => u.name.toLowerCase())
    let {transactions, categories, locations, months} = this.props.finance
    // let originalTransactions = transactions
    categories = categories.map(cat => cat.toLowerCase())
    locations = locations.map(loc => loc.toLowerCase())
    months = months.map(month => month.toLowerCase())
    const rawFilters = this.state.filter.split(",")
      .map(f => f.trim().toLowerCase())
    let tags = new Set()

    // Prepare valid months and years
    let years = new Set()
    let monthNames = new Set()
    months.forEach(m => {
      const mnY = m.split(" ")
      monthNames.add(mnY[0])
      years.add(parseInt(mnY[1]))
    })
    years = [...years]
    monthNames = [...monthNames]

    // Create tags from filters
    rawFilters.forEach(f => {
      let involved = false
      if (f.includes('involved')) {
        involved = true
        f = f.replace('involved', '')
        f = f.trim()
      }
      const foundNr = f.match(/[\d.]+/)

      // Apply filter
      if (f === 'me') {
        tags.add(`Involved: ${user.name.toLowerCase()}`)
        transactions = transactions.filter(trans =>
          trans.affected_users.filter(u => u._id === user._id).length > 0)
      } else if (f.length >= 3) {
        let categoryMatches = categories.filter(cat => cat.includes(f))
        let locationMatches = locations.filter(loc => loc.includes(f))
        let nameMatches = wgNames.filter(n => n.includes(f))
        let monthMatches = monthNames.filter(m => m.includes(f))
        if (categoryMatches.length > 0) {
          tags.add(`Category: ${categoryMatches[0].toLowerCase()}`)
          transactions = transactions.filter(trans =>
            Array.from(categoryMatches).includes(trans.category.toLowerCase()))
        } else if (locationMatches.length > 0) {
          tags.add(`Location: ${locationMatches[0].toLowerCase()}`)
          transactions = transactions.filter(trans =>
            Array.from(locationMatches).includes(trans.location.toLowerCase()))
        } else if (nameMatches.length > 0) {
          if (involved) {
            tags.add(`Involved: ${nameMatches[0].toLowerCase()}`)
            transactions = transactions.filter(trans =>
              trans.affected_users.filter(u => u.name.toLowerCase() === nameMatches[0]).length > 0)
          } else {
            tags.add(`Paid By: ${nameMatches[0]}`)
            transactions = transactions.filter(trans => trans.owner.name.toLowerCase() === nameMatches[0])
          }
        } else if (foundNr != null) {
          const amount = parseFloat(foundNr[0])
          if (f.includes('>=')) {
            tags.add(`Amount >= ${amount}`)
            transactions = transactions.filter(trans => trans.amount >= amount)
          } else if (f.includes('<=')) {
            tags.add(`Amount <= ${amount}`)
            transactions = transactions.filter(trans => trans.amount <= amount)
          } else if (f.includes('>')) {
            tags.add(`Amount > ${amount}`)
            transactions = transactions.filter(trans => trans.amount > amount)
          } else if (f.includes('<')) {
            tags.add(`Amount < ${amount}`)
            transactions = transactions.filter(trans => trans.amount < amount)
          } else if (years.includes(amount)) {
            tags.add(`Year ${amount}`)
            transactions = transactions.filter(trans => new Date(trans.date_of_transaction).getFullYear() === amount)
          } else {
            tags.add(`Amount: ${amount}`)
            transactions = transactions.filter(trans => trans.amount === amount)
          }
        } else if (monthMatches.length > 0) {
          const month = monthMatches[0]
          tags.add(month)
          transactions = transactions.filter(trans =>
            new Date(trans.date_of_transaction).toLocaleString('en-gb', {month: 'long'}).toLowerCase() === month
          )
        } else {
          // Try to match comment
          let newTransactions = transactions.filter(trans => trans.comment.toLowerCase().includes(f))
          if (newTransactions.length > 0) {
            tags.add(`Comment contains "${f}"`)
            transactions = newTransactions
          }
        }
      }
    })

    this.setState({
      filterTags: Array.from(tags),
      allTransactions: transactions
    })
  }

  render() {
    return (
      <Row>
        <Col>
          <h2>Add New Transaction</h2>
          {this.renderForm()}
        </Col>
        <Col>
          <h2>Recent Transactions</h2>
          {this.renderTransactions()}
        </Col>
      </Row>
    )
  }
}


Finance.propTypes = {
  finance: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  general: PropTypes.object.isRequired
}


const mapStateToProps = (state) => {
  return ({
    auth: state.auth,
    finance: state.finance,
    general: state.general
  })
}

export default connect(
  mapStateToProps,
  {loadTransactions, bookTransaction, archiveTransaction, loadWgUsers, pushToast, showModal}
)(Finance)
