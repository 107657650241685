import axios from 'axios';
import {API_URL} from '../config'

import {tokenConfig} from "./authActions"
import {pushToast} from "./toastActions"
import {ITEMS_ARCHIVED, ITEMS_ARCHIVING, ITEMS_LOADED} from "./types"

// Get all transactions
export const loadItems = () => (dispatch, getState) => {
  // User loading
  axios
    .get(`${API_URL}/api/shopping/item`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: ITEMS_LOADED,
        payload: res.data
      })
    })
    .catch(err => {
      console.log(err)
    });
};

// Add new item
export const addItem = (item, qty) => (dispatch, getState) => {
  const body = {item, qty}
  axios
    .post(`${API_URL}/api/shopping/item`, body, tokenConfig(getState))
    .then(res => {
      dispatch(loadItems())
      dispatch(pushToast("Item Success", "The Item was successfully added!"))
    })
    .catch(err => {
      console.log(err)
      dispatch(pushToast(
        "Item Error",
        "There was an error while adding the item!"
      ))
    });
};

// // Archive items
export const archiveItems = (ids) => (dispatch, getState) => {
 dispatch({ type: ITEMS_ARCHIVING });
  const body = {ids: [...ids]}
  axios
    .delete(`${API_URL}/api/shopping/item`, {data: body, headers: tokenConfig(getState).headers})
    .then(res => {
      dispatch(loadItems())
      dispatch(pushToast(
        "Item Success",
        "The selected items were successfully archived!"))
      dispatch({ type: ITEMS_ARCHIVED })
    })
    .catch(err => {
      console.log(err)
      dispatch(pushToast(
        "Item Error",
        "There was an error while archiving the items!"
      ))
      dispatch({ type: ITEMS_ARCHIVED });
    });
};
