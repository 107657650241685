import React, {Component} from 'react'
import {Button, Form, Col, Row, InputGroup, Dropdown} from "react-bootstrap"

import {resetGame, loadGame, setName, registerName, submitScore, nextRound} from "../actions/wavelengthActions"
import {connect} from "react-redux";
// import {pushToast} from "../actions/toastActions";
import PropTypes from "prop-types";

class Wavelength extends Component {

  constructor(props) {
    super(props);
    this.handleReset = this.handleReset.bind(this)
    this.handleNameSubmit = this.handleNameSubmit.bind(this)
    this.handleScoreSubmit = this.handleScoreSubmit.bind(this)
    this.update = this.update.bind(this)
    this.updateSelect = this.updateSelect.bind(this)
    this.handleNextRound = this.handleNextRound.bind(this)
    this.state = {
      slider: 0,
      tempName: '',
      selectedItem: '',
      setNextPlayer: false,
    };
  }

  componentDidMount() {
    this.props.loadGame()
    if (this.props.game.name) {
      if (this.props.game.active?.currentScores) {
        const {players, currentScores, currentPlayer} = this.props.game.active
        const myScore = currentScores[players.indexOf(this.props.game.name)]
        if (myScore > 0) {
          this.setState({slider: myScore})
        }
        const nextPlayer = (currentPlayer + 1) % players.length
        this.setState({selectedItem: players[nextPlayer]})
      }
    }
    this.interval = setInterval(this.update, 2000);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.game.active) {
      const {players, currentPlayer, currentScores} = this.props.game.active
      const nextPlayer = (currentPlayer + 1) % players.length
      const isVotingCompleted = currentScores.filter(s => s > 0).length >= currentScores.length - 1
      if (isVotingCompleted) {
        if (!this.state.setNextPlayer && this.state.selectedItem !== players[nextPlayer]) {
          this.setState({selectedItem: players[nextPlayer], setNextPlayer: true})
        }
      } else if (this.state.setNextPlayer) {
        this.setState({setNextPlayer: false})
      }
    }
  }

  update() {
    // update game state
    this.props.loadGame()
  }

  componentWillUnmount() {
    // Clear the interval to prevent memory leaks
    clearInterval(this.interval);
  }


  handleSliderChange = (event) => {
    this.setState({slider: event.target.value});
  }

  handleNameSubmit(e) {
    e.preventDefault()
    const {tempName} = this.state
    this.props.setName(tempName)
  }

  handleScoreSubmit(e) {
    e.preventDefault()
    const {slider} = this.state
    const {name} = this.props.game
    if (name) {
      this.props.submitScore(name, slider)
    }
  }

  handleReset(e) {
    e.preventDefault()
    this.props.resetGame()
  }

  handleNextRound(e) {
    e.preventDefault()
    this.setState({setNextPlayer: false})
    this.props.nextRound(this.state.selectedItem)
  }

  updateSelect(e) {
    this.setState({selectedItem: e, setNextPlayer: true})
  }

  renderNameForm() {
    return (
      <Form onSubmit={this.handleNameSubmit} style={{marginTop: '20px'}}>
        <Form.Group controlId="item" className="text-center">
          <InputGroup className="mt-2">
            <Form.Control
              value={this.state.tempName}
              onChange={(e) => this.setState({tempName: e.target.value})}
              placeholder="Enter your name"
              className='col-md-4'
            />
            <InputGroup.Append>
              <Button style={{padding: '0px 35px'}} type="submit" variant="outline-primary">Join Game</Button>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>
      </Form>
    )
  }

  renderGame() {
    if (!this.props.game.active) {
      return (
        <>
          <p>Cannot find an active game.</p>
          <Button
            style={{
              padding: '5px 10px',
              backgroundColor: '#007bff',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              marginBottom: '20px',
              marginTop: '20px'
            }}
            type="submit"
            onClick={this.handleReset}
            variant="primary"
          >
            Reset Room
          </Button>
        </>
      )
    }

    const {players, currentPlayer, category, target, currentScores} = this.props.game.active
    const {name} = this.props.game

    if (!players.includes(name)) {
      return this.renderNameForm()
    }

    const myIndex = players.indexOf(name)

    //console.log(this.props.game.active)

    const votingCompleted = currentScores.filter(s => s > 0).length >= currentScores.length - 1
    const hasVoted = currentScores[myIndex] > 0

    const isActive = players[currentPlayer] === name
    const colors = currentScores.map(score => {
      if (score > 0 && !votingCompleted) {
        return 'lightgreen'
      } else {
        return 'white'
      }
    })


    let results = []
    let sum = 0
    if (votingCompleted) {
      for (let i = 0; i < players.length; i++) {
        if (i !== currentPlayer) {
          let str = 'perfect!'
          if (currentScores[i] - target < 0) {
            str = currentScores[i] - target
          } else if (currentScores[i] - target > 0) {
            str = '+' + (currentScores[i] - target)
          }
          results.push({
            'name': players[i],
            'guess': currentScores[i],
            'diff': Math.abs(currentScores[i] - target),
            'str': str
          })
          sum += currentScores[i]
        }
      }
    }
    const avg = Math.round(sum / (players.length - 1))
    const diff = avg - target
    let avgString = 'perfect!'
    if (diff < 0) {
      avgString = avg - target
    } else if (diff > 0) {
      avgString = '+' + (avg - target)
    }

    results.push({
      'name': 'Group Average',
      'guess': avg,
      'diff': Math.abs(diff),
      'str': avgString
    })

    results.sort((a, b) => a['diff'] - b['diff'])

    const sliderStyle ={
      width: '300px',
    }

    return (
      <>
        {players.length === 1 ?
          <div>
            <span>The category is: </span>
            <span><strong>{category}</strong><br/></span>
            <span>The target score is: <strong>{target}</strong></span>
            <Form style={{marginTop: '20px', width: '80%'}}>
              <Form.Group>
                <Form.Control
                  type="range"
                  min="0"
                  max="100"
                  value={target}
                  disabled={true}
                  style={sliderStyle}
                  className="custom-slider"
                />
              </Form.Group>
            </Form>
            Try to convey a single thought that lies on the provided spectrum. <br/>
            Be as close as possible to your target, conceptually. <br/>
            Stay on topic and don't mention any numbers!<br/>
            <Button variant="primary" onClick={this.handleNextRound} style={{marginTop: '25px'}}>
              Next Round
            </Button>
          </div>
          :
          <>
            <div style={{marginBottom: '25px'}}>
              <span>with </span>
              {players.map((name, index) => (
                <span key={index} style={{color: colors[index]}}>
            {index === currentPlayer ? <strong style={{textDecoration: 'underline'}}>{name}</strong> : name}
                  {index < players.length - 1 ? ', ' : ''}
          </span>
              ))}
            </div>
            {votingCompleted ?
              <div>
                <span>{players[currentPlayer]} gave an idea for the spectrum: </span>
                <span><strong>{category}</strong><br/></span>
                <span>Their target score was: <strong>{target}</strong></span>
                <h3 style={{marginTop: '20px'}}>Results</h3>
                {results.map((r, index) => (
                  <span key={index}>{r['name']}: {r['guess']} ({r['str']})<br/></span>
                ))}

                {this.props.auth.isAuthenticated ?
                  <div className="d-flex align-items-center" style={{marginTop: '20px'}}>
                    <Dropdown onSelect={this.updateSelect} className="me-2" style={{paddingRight: '10px'}}>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        {this.state.selectedItem}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {players.map((item, index) => (
                          <Dropdown.Item key={index} eventKey={item} active={item === this.state.selectedItem}>
                            {item}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>

                    <Button variant="primary" onClick={this.handleNextRound}>
                      Next Round
                    </Button>
                  </div> : ''}
              </div>
              :
              isActive ?
                <div>
                  <span>Your turn! <br/>The category is: </span>
                  <span><strong>{category}</strong><br/></span>
                  <span>The target score is: <strong>{target}</strong></span>
                  <Form style={{marginTop: '20px', width: '80%'}}>
                    <Form.Group>
                      <Form.Control
                        type="range"
                        min="0"
                        max="100"
                        value={target}
                        disabled={true}
                        style={sliderStyle}
                        className="custom-slider"
                      />
                    </Form.Group>
                  </Form>
                  Try to convey a single thought that lies on the provided spectrum. <br/>
                  Be as close as possible to your target, conceptually. <br/>
                  Stay on topic and don't mention any numbers!
                </div>
                :
                <div>
            <span>{players[currentPlayer]} is giving an idea for the spectrum: <br/>
              <strong>{category}</strong><br/></span>
                  <Form style={{marginTop: '30px', marginBottom: '30px', width: '80%'}} onSubmit={this.handleScoreSubmit}>
                    <Form.Group>
                      <Form.Label>Your Guess: <strong>{this.state.slider}</strong></Form.Label>
                      <InputGroup className="mt-2">
                        <Form.Control
                          type="range"
                          min="0"
                          max="100"
                          value={this.state.slider}
                          onChange={this.handleSliderChange}
                          style={sliderStyle}
                          disabled={hasVoted}
                          className="custom-slider"
                        />
                        <InputGroup.Append>
                          <Button
                            style={{padding: '5px 10px', marginLeft: '10px'}}
                            type="submit"
                            variant="outline-primary"
                            disabled={hasVoted}
                          >
                            {hasVoted ? 'Locked In' : 'Lock In'}
                          </Button>
                        </InputGroup.Append>
                      </InputGroup>
                    </Form.Group>
                  </Form>
                  <span>Set the slider to where you think the idea falls on the spectrum, conceptually.<br/>
            You can discuss with other players before locking in your answer.</span>
                </div>
            }
          </>
        }


        {!this.props.auth.isAuthenticated ? '' :
          <>
            <br/>
            <Button
              style={{
                padding: '5px 10px',
                backgroundColor: '#007bff',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                marginBottom: '20px',
                marginTop: '20px'
              }}
              type="submit"
              onClick={this.handleReset}
              variant="primary"
            >
              Reset Room
            </Button>
          </>
        }

      </>
    )

  }

  render() {
    return (
      <Row>
        <Col>
          <h2 style={{marginBottom: '10px'}}>Wavelength</h2>
          {this.renderGame()}
        </Col>
      </Row>


    )
  }
}

Wavelength.propTypes = {
  auth: PropTypes.object.isRequired,
  general: PropTypes.object.isRequired,
  game: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return ({
    auth: state.auth,
    general: state.general,
    game: state.game
  });
};
export default connect(
  mapStateToProps,
  {resetGame, loadGame, setName, registerName, submitScore, nextRound}
)(Wavelength);
