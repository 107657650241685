import React, {Component} from 'react'
import PropTypes from "prop-types"
import SideNav, {Nav, NavIcon, NavItem, NavText, Toggle} from '@trendmicro/react-sidenav'
import {connect} from "react-redux"
import LoginModal from "./modals/LoginModal"
import {showModal} from "../actions/modalActions"
import {loadUser} from "../actions/authActions"


class Sidenav extends Component {

  componentDidMount() {
    this.props.loadUser()
  }

  openLoginModal() {
    this.props.showModal({
        open: true,
        closeModal: this.closeModal
      },
      'login'
    )
  }

  render() {
    const {pathname} = this.props.routeProps.location
    const currentPage = pathname.substr(1)
    const {history} = this.props.routeProps
    const {user, isAuthenticated} = this.props.auth

    const iconStyle = {
      fontSize: '1.75em',
      color: '#fff',
      verticalAlign: 'middle'
    }

    const textStyle = {
      color: '#fff'
    }


    return (
      <SideNav
        onSelect={(selected) => {
          const to = '/' + selected;
          if (selected === 'profile' && !isAuthenticated) {
            this.openLoginModal()
          } else if (pathname !== to) {
            history.push(to);
          }
        }}
        className="bg-dark"
      >
        <LoginModal/>
        <Toggle/>
        <Nav selected={currentPage}>
          {isAuthenticated ?
            [
              <NavItem eventKey="transactions" key="transactions">
                <NavIcon>
                  <i className="fa fa-comments-dollar" style={iconStyle}/>
                </NavIcon>
                <NavText style={textStyle}>
                  Transactions
                </NavText>
              </NavItem>,
              <NavItem eventKey="balance" key="balance" style={textStyle}>
                <NavIcon>
                  <i className="fa fa-chart-pie" style={iconStyle}/>
                </NavIcon>
                <NavText style={textStyle}>
                  Balance
                </NavText>
              </NavItem>,
              <NavItem eventKey="wavelength" key="wavelength" style={textStyle}>
                <NavIcon>
                  <i className="fa fa-circle-notch" style={iconStyle}/>
                </NavIcon>
                <NavText style={textStyle}>
                  Wavelength
                </NavText>
              </NavItem>,
              <NavItem eventKey="shopping" key="shopping">
                <NavIcon>
                  <i className="fa fa-shopping-cart" style={iconStyle}/>
                </NavIcon>
                <NavText style={textStyle}>
                  Shopping List
                </NavText>
              </NavItem>,
              <NavItem eventKey="profile" key="profile">
                <NavIcon>
                  {user
                    ? <img className="rounded-circle" src={user.picture} style={{width: 35, height: 35}}
                           alt={user.name}/>
                    : <i className="fa fa-user" style={iconStyle}/>
                  }
                </NavIcon>
                <NavText style={textStyle}>
                  Profile
                </NavText>
              </NavItem>
            ]
            :
            <NavItem eventKey="profile">
              <NavIcon>
                <i className="fa fa-user" style={iconStyle}/>
              </NavIcon>
              <NavText style={textStyle}>
                Login
              </NavText>
            </NavItem>
          }
        </Nav>
      </SideNav>
    )
  }
}

Sidenav.propTypes = {
  auth: PropTypes.object.isRequired,
  routeProps: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {
  return ({
    cookies: ownProps.cookies,
    auth: state.auth
  });
};

export default connect(
  mapStateToProps,
  {showModal, loadUser}
)(Sidenav);
