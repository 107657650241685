import axios from 'axios';
import {API_URL} from '../config'

import {AUTH_ERROR, LOGIN_SUCCESS, LOGOUT_SUCCESS, USER_LOADED, USER_LOADING} from './types';

// Check token & load user
export const loadUser = () => (dispatch, getState) => {
  // User loading
  dispatch({ type: USER_LOADING });
  axios
    .get(`${API_URL}/api/user`, tokenConfig(getState))
    .then(res => {
        dispatch({
          type: USER_LOADED,
          payload: res.data
        })
      })
    .catch(err => {
      console.log(err)
      dispatch({
        type: AUTH_ERROR
      });
    });
};

// Login User
export const login = (payload) => dispatch => {
  dispatch({
    type: LOGIN_SUCCESS,
    payload
  })
}


// Logout User
export const logout = () => {
  return {
    type: LOGOUT_SUCCESS
  };
};


// Setup config/headers and token
export const tokenConfig = getState => {
  // Get token from localstorage
  const token = getState().auth.token;

  // Headers
  const config = {
    headers: {
      'Content-type': 'application/json'
    }
  };

  // If token, add to headers
  if (token) {
    config.headers['x-auth-token'] = token;
  }

  return config;
};
