import axios from 'axios';
import {API_URL} from '../config'

import {WG_USERS_LOADED} from './types';
import {tokenConfig} from "./authActions"

// Get all WG users
export const loadWgUsers = () => (dispatch, getState) => {
  axios
    .get(`${API_URL}/api/user/wg`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: WG_USERS_LOADED,
        payload: res.data
      })
    })
    .catch(err => {
      console.log(err)
    });
};
