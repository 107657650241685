import React, {Component} from 'react'
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {pushToast} from "../actions/toastActions"
import ASWrapper from "./ASWrapper"
import {addItem, archiveItems, loadItems} from "../actions/shoppingActions"
import BootstrapTable from "react-bootstrap-table-next"

const suggestions = ['Toilet Paper', 'Onions', 'Garlic', 'Trash Bags', 'Trash Stamps']

class ShoppingList extends Component {

  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.addSelection = this.addSelection.bind(this)
    this.removeSelection = this.removeSelection.bind(this)
    this.archiveSelection = this.archiveSelection.bind(this)
    this.state = {
      item: '',
      qty: '1',
      selected: new Set(),
      archiveDisabled: true,
      archiveButtonText: 'Archive Selection'
    }
  }

  componentDidMount() {
    this.props.loadItems()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.items.archiving !== this.props.items.archiving) {
      this.setState({
        archiveDisabled: this.props.items.archiving,
        archiveButtonText: this.props.items.archiving ? 'Archiving Items...' : 'Archive Selection'
      })
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    const {item, qty} = this.state

    // default to 1, but have to confirm again
    if (qty === '') {
      this.setState({
        qty: '1'
      })
      return
    }

    this.props.addItem(item, qty)
    this.setState({
      item: '',
      qty: '1',
      archiveDisabled: true,
    })
  }

  addSelection(id) {
    let {selected} = this.state
    selected.add(id)
    this.setState({
      selected,
      archiveDisabled: false
    })
  }

  removeSelection(id) {
    let {selected} = this.state
    selected.delete(id)
    this.setState({
      selected,
      archiveDisabled: selected.size <= 0
    })

  }

  archiveSelection() {
    this.props.archiveItems(this.state.selected)
  }

  renderForm() {
    return (
      <>
        <Form onSubmit={this.handleSubmit}>
          <Form.Group controlId="item" className="text-center">
            <ASWrapper
              name="item"
              fieldValue={this.state.item}
              onChange={(name, item) => this.setState({item})}
              suggestions={suggestions}
              placeholder={"What needs to be bought?"}
            />
            <InputGroup className="mt-2">
              <Form.Control
                value={this.state.qty}
                onChange={(e) => this.setState({qty: e.target.value})}
                placeholder="How much or how many?"
              />
              <InputGroup.Append>
                <Button style={{padding: '0px 35px'}} type="submit" variant="outline-primary">Add Item</Button>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        </Form>
      </>
    )
  }

  renderList() {
    if (!this.props.items.active || this.props.items.active.length === 0) {
      return <p>Currently no items on the shopping list.</p>
    }

    // let data = []
    // for(const item of this.props.items.active) {
    //   data.push(item)
    // }

    let columns = [
      {
        dataField: 'qty',
        text: 'Quantity',
        // style: {fontWeight: 'bold'}
      },
      {
        dataField: 'item',
        text: 'Item',
        // formatter: colData => colData.toFixed(2),
        // sort: true
      }
    ]

    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      style: {backgroundColor: 'green'},
      onSelect: (row, isSelect, rowIndex, e) => {
        if (isSelect) {
          this.addSelection(row._id)
        } else {
          this.removeSelection(row._id)
        }
      },
    };

    return (
      <>
        <BootstrapTable
          keyField='_id'
          data={this.props.items.active}
          columns={columns}
          striped
          condensed
          bordered={false}
          selectRow={selectRow}
        />
        <div className="text-center">
          <Button onClick={this.archiveSelection} variant="outline-primary" disabled={this.state.archiveDisabled}>
            {this.state.archiveButtonText}
          </Button>
        </div>
      </>
    )

  }

  render() {
    return (
      <Row>
        <Col xl={6}>
          <h2>Shopping List</h2>
          {this.renderForm()}
          <br/>
          {this.renderList()}
        </Col>
      </Row>
    )
  }
}

ShoppingList.propTypes = {
  auth: PropTypes.object.isRequired,
  general: PropTypes.object.isRequired,
  items: PropTypes.object.isRequired
}


const mapStateToProps = (state) => {
  return ({
    auth: state.auth,
    general: state.general,
    items: state.items
  });
};

export default connect(
  mapStateToProps,
  {pushToast, addItem, loadItems, archiveItems}
)(ShoppingList);
