import React, {Component} from 'react'
import {connect} from "react-redux"
import {hideModal} from "../../actions/modalActions"
import {Button, Modal} from "react-bootstrap"

class ConfirmModal extends Component {

  modalType = 'confirm'

  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      yesFct: () => {}
    };
    this.closeModal = this.closeModal.bind(this)
    this.applyAndClose = this.applyAndClose.bind(this)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Require correct modal type
    if (prevProps.modalType !== this.modalType && this.props.modalType !== this.modalType)
      return

    // Set state
    if (prevProps !== this.props) {
      this.setState({
        modalIsOpen: this.props.modalProps.open,
        yesFct: this.props.modalProps.yesFct ? this.props.modalProps.yesFct : () => {}
      })
    }
  }

  closeModal() {
    this.props.hideModal()
  }

  applyAndClose(args) {
    this.state.yesFct(...args)
    this.closeModal()
  }

  render() {
    const {modalProps} = this.props

    return (
      <div>
        <Modal show={this.state.modalIsOpen} onHide={this.closeModal}>
          <Modal.Header>
            <Modal.Title>{modalProps.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalProps.body}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.applyAndClose(modalProps.args)}>
              yes
            </Button>
            <Button variant="primary" onClick={this.closeModal}>
              no
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }

}

const mapStateToProps = state => ({
  ...state.modal
})

export default connect(
  mapStateToProps,
  {hideModal}
)(ConfirmModal)
