import React, {Component} from 'react'
import PropTypes from "prop-types"
import {Col, Row} from "react-bootstrap"
import {Link} from "react-router-dom"
import {logout} from "../actions/authActions"
import {connect} from "react-redux"

class Profile extends Component {

  render() {
    return (
      <Row>
        <Col>
          <p>
            <Link to={'/'} onClick={this.props.logout}>Logout</Link>
          </p>
        </Col>
      </Row>
    )
  }
}

Profile.propTypes = {
  auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {logout}
)(Profile);
