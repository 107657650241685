import {GAME_LOADED,GAME_SET_NAME} from '../actions/types';
// import {act} from "react-dom/test-utils";


const initialState = {
  active: null,
  name: null,
  // archiving: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GAME_LOADED:
      return {
        ...state,
        active: action.payload
      }
    case GAME_SET_NAME:
      return {
        ...state,
        name: action.payload
      }

    default:
      return state;
  }
}

