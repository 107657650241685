import React, {Component} from 'react'
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap"
import {connect} from "react-redux"
import {archiveTransaction, loadTransactions} from "../actions/financeActions"
import {loadWgUsers} from "../actions/generalActions"
import BootstrapTable from 'react-bootstrap-table-next'
import PropTypes from "prop-types"

class Balance extends Component {

  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.state = {
      showMonth: 'All',
      allExpenses: []
    }
  }

  componentDidMount() {
    this.refreshData()
    this.setState({
      allExpenses: this.buildData()
    })
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  refreshData() {
    this.props.loadTransactions()
    this.props.loadWgUsers()
  }

  static addValueToPropertyForUser(expenses, category, userid, property, value) {
    if (expenses.hasOwnProperty(category)) {
      if (expenses[category].hasOwnProperty(userid)) {
        if (expenses[category][userid].hasOwnProperty(property)) {
          expenses[category][userid][property] += value
          if (expenses[category].total.hasOwnProperty(property)) {
            expenses[category].total[property] += value
          } else {
            expenses[category].total = {...expenses[category].total, [property]: value}
          }
          return expenses
        }
        // console.log('noprop', value)
        expenses[category][userid] = {...expenses[category][userid], [property]: value}
        if (expenses[category].total.hasOwnProperty(property)) {
          expenses[category].total[property] += value
        } else {
          expenses[category].total = {...expenses[category].total, [property]: value}
        }
        return expenses
      }
      // console.log('nouser')
      expenses[category] = {...expenses[category], [userid]: {[property]: value}}
      if (expenses[category].total.hasOwnProperty(property)) {
        expenses[category].total[property] += value
      } else {
        expenses[category].total = {...expenses[category].total, [property]: value}
      }
    } else {
      // console.log('nocat', value)
      expenses = {
        ...expenses,
        [category]: {
          category: category,
          [userid]: {[property]: value},
          total: {[property]: value},
        }
      }
    }

    return expenses

  }

  buildData() {
    let {transactions} = this.props.finance
    if (!transactions) {
      return []
    }

    const {showMonth} = this.state
    let expenses = {}

    if (showMonth !== 'All') {
      transactions = transactions.filter((trans) => {
        const date = new Date(trans.date_of_transaction)
        const month = date.toLocaleString('en-gb', {month: 'long'})
        return `${month} ${date.getFullYear()}` === showMonth
      })
    }

    for (let t of transactions) {
      t.amount = parseFloat(t.amount)
      const affectedUsers = t.affected_users.map(au => au._id)
      const consumeFactor = t.affected_users.length

      if (consumeFactor === 0) {
        continue
      }

      expenses = Balance.addValueToPropertyForUser(expenses, t.category, t.owner._id, 'paid', t.amount)
      for (let au of affectedUsers) {
        expenses = Balance.addValueToPropertyForUser(expenses, t.category, au, 'consumed', t.amount / consumeFactor)
      }

    }
    expenses = Object.values(expenses)
    return expenses
  }

  renderBalance(expenses) {
    const balance = {}
    const {user} = this.props.auth
    const {wgUsers} = this.props.general
    const wgUserIds = Object.values(wgUsers).map(user => user._id)
    for (let wgUser of Object.values(wgUsers)) {
      balance[wgUser._id] = {
        name: wgUser.name,
        totalPaid: 0,
        totalConsumed: 0,
        totalBalance: 0
      }
    }
    for (let expense of expenses) {
      for (let key of Object.keys(expense)) {
        if (wgUserIds.includes(key)) {
          if (expense[key].hasOwnProperty('paid')) {
            balance[key].totalPaid += expense[key].paid
          }
          if (expense[key].hasOwnProperty('consumed')) {
            balance[key].totalConsumed += expense[key].consumed
          }
        }
      }
    }


    for (let wgUserId of wgUserIds) {
      balance[wgUserId].totalBalance = balance[wgUserId].totalPaid - balance[wgUserId].totalConsumed
    }

    const rowStyle = (row) => {
      const style = {}
      if (row.name === user.name) {
        style.backgroundColor = '#363636'
      }
      return style
    }

    let columns = [
      {
        dataField: 'name',
        text: 'Name',
        sort: true,
        style: {fontWeight: 'bold'}
      },
      {
        dataField: 'totalPaid',
        text: 'Expended',
        formatter: colData => colData.toFixed(2),
        sort: true
      },
      {
        dataField: 'totalConsumed',
        text: 'Consumed',
        formatter: colData => colData.toFixed(2),
        sort: true
      },
      {
        dataField: 'totalBalance',
        text: 'Current balance',
        style: {fontWeight: 'bold'},
        formatter: colData => {
          const color = colData >= 0 ? 'green' : 'red'
          return <span style={{color}}>{colData.toFixed(2)}</span>
        },
        sort: true
      }
    ]

    const defaultSorted = [{
      dataField: 'totalBalance',
      order: 'desc'
    }]

    return <BootstrapTable
      keyField='name'
      data={Object.values(balance)}
      columns={columns}
      striped
      condensed
      bordered={false}
      defaultSorted={defaultSorted}
      rowStyle={rowStyle}
    />


  }

  renderData(expenses) {
    const {wgUsers} = this.props.general
    const {user} = this.props.auth
    const getHighlightColor = (wgUser) => {
      if (user._id === wgUser._id) {
        return {backgroundColor: '#363636'}
      }
    }
    const styles = {
      paid: {color: 'green'},
      consumed: {color: 'red'}
    }
    let columns = [{
      dataField: 'category',
      text: 'Category',
      sort: true,
      footer: 'Total',
      style: {fontWeight: 'bold'}
    }]
    for (let wgUser of Object.values(wgUsers)) {
      columns.push({
        dataField: wgUser._id,
        text: wgUser.name,
        style: getHighlightColor(wgUser),
        footerStyle: getHighlightColor(wgUser),
        headerStyle: getHighlightColor(wgUser),
        sort: true,
        sortValue: (cell) => cell && cell.hasOwnProperty('paid') ? cell.paid : 0,
        footer: columnData => {
          let paid = columnData.reduce((acc, item) => acc + (item && item.paid ? item.paid : 0), 0)
          let consumed = columnData.reduce((acc, item) => acc + (item && item.consumed ? item.consumed : 0), 0)
          return (<>
            <span style={styles.paid}>{paid.toFixed(2)}
            </span> / <span style={styles.consumed}>{consumed.toFixed(2)}
            </span></>)
        },
        formatter: colData => {
          let paid = colData && colData.hasOwnProperty('paid') ? colData.paid : 0
          let consumed = colData && colData.hasOwnProperty('consumed') ? colData.consumed : 0
          return (<>
            <span style={styles.paid}>{paid.toFixed(2)}
            </span> / <span style={styles.consumed}>{consumed.toFixed(2)}
            </span></>)
        }
      })
    }

    columns.push({
      dataField: 'total',
      text: 'Total',
      sort: true,
      sortValue: (cell) => cell && cell.hasOwnProperty('paid') ? cell.paid : 0,
      footer: columnData => columnData.reduce((acc, item) => acc + (item && item.paid ? item.paid : 0), 0).toFixed(2),
      formatter: colData => {
        let out = colData && colData.hasOwnProperty('paid') ? colData.paid : 0
        return out.toFixed(2)
      }
    })

    const defaultSorted = [{
      dataField: 'total',
      order: 'desc'
    }]

    return <BootstrapTable
      keyField='category'
      data={expenses}
      columns={columns}
      striped
      condensed
      bordered={false}
      defaultSorted={defaultSorted}
    />
  }

  render() {
    const expenses = this.buildData()
    return (
      <Row>
        <Col sm={12}>
          <h2>Balance</h2>
          {this.renderBalance(this.state.allExpenses)}
        </Col>
        <Col sm={12}>
          <h2>Detailed Expenses / Consumption</h2>
          <Form onSubmit={(e) => e.preventDefault()} noValidate>
            <Form.Group as={Row} controlId="month">
              <Col sm={4}>
                <InputGroup className="mt-2">
                  <Form.Control size="sm" name="showMonth" as="select" value={this.state.showMonth}
                                onChange={this.handleChange}>
                    {['All', ...this.props.finance.months].map((month, index) => {
                      return <option key={index} value={month}>{month}</option>
                    })}
                  </Form.Control>
                    <InputGroup.Append>
                      <Button style={{padding: '0px 20px'}} type="submit" variant="outline-primary"
                              disabled={this.state.showMonth === 'All'}
                              onClick={() => {
                                this.props.history.push(
                                  '/transactions',
                                  {filter: this.state.showMonth.replace(" ", ", ")}
                                )
                              }}
                      >
                        Details
                      </Button>
                    </InputGroup.Append>
                </InputGroup>
              </Col>
            </Form.Group>
          </Form>
          {this.renderData(expenses)}
        </Col>
      </Row>
    )
  }
}

Balance.propTypes = {
  finance: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  general: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return ({
    auth: state.auth,
    finance: state.finance,
    general: state.general
  })
}

export default connect(
  mapStateToProps,
  {loadTransactions, archiveTransaction, loadWgUsers}
)(Balance)
