import axios from 'axios';
import {API_URL} from '../config'

import {TRANSACTIONS_LOADED} from './types';
import {tokenConfig} from "./authActions"
import {pushToast} from "./toastActions"

// Get all transactions
export const loadTransactions = () => (dispatch, getState) => {
  // User loading
  axios
    .get(`${API_URL}/api/finance/transaction`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TRANSACTIONS_LOADED,
        payload: res.data
      })
    })
    .catch(err => {
      console.log(err)
    });
};

// Add new transaction
export const bookTransaction = (owner, amount, location, category, affected, comment, date,
                                splitAmount, splitInvolved, splitComment) => (dispatch, getState) => {

  const body = {owner, amount, location, category, affected, comment, date, splitAmount, splitInvolved, splitComment}

  axios
    .post(`${API_URL}/api/finance/transaction`, body, tokenConfig(getState))
    .then(res => {
      dispatch(loadTransactions())
      dispatch(pushToast("Transaction Success", "The Transaction was successfully added!"))
    })
    .catch(err => {
      console.log(err)
      dispatch(pushToast(
        "Transaction Error",
        "There was an error while adding the transaction!"
      ))
    });
};

// Archive Transaction
export const archiveTransaction = (id) => (dispatch, getState) => {

  const body = {id}
  axios
    .delete(`${API_URL}/api/finance/transaction`, {data: body, headers: tokenConfig(getState).headers})
    .then(res => {
      dispatch(loadTransactions())
      dispatch(pushToast(
        "Transaction Success",
        "The Transaction was successfully deleted! It can still be reviewed or restored in the database."))
    })
    .catch(err => {
      console.log(err)
      dispatch(pushToast(
        "Transaction Error",
        "There was an error while deleting the transaction!"
      ))
    });
};
