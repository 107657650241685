import React, {Component} from 'react';
import './App.css';
import {Route, Switch} from 'react-router-dom'
import {Container} from "react-bootstrap"
import Sidenav from "./components/Sidenav"
import {withCookies} from "react-cookie"
import Profile from "./components/Profile"
import Finance from "./components/Finance"
import Balance from "./components/Balance"
import ShoppingList from "./components/ShoppingList"
import Wavelength from "./components/Wavelength";

class App extends Component {
  render() {
    return (
      <>
        <Route path={'/'} render={routeProps => (
          <Sidenav routeProps={routeProps} cookies={this.props.cookies}/>
        )}
        />
        {/*<Topnav cookies={this.props.cookies}/>*/}
        <div style={{height: 100}} />
        <Container>
          <Switch>
            <Route path="/transactions" component={Finance}/>
            <Route path="/balance" component={Balance}/>
            <Route path="/shopping" component={ShoppingList}/>
            <Route path="/profile" component={Profile}/>
            <Route path="/wavelength" component={Wavelength}/>
            <Route path="/" component={ShoppingList}/>
          </Switch>
        </Container>
      </>
    )
  }

}

export default withCookies(App);
