import React, {Component} from 'react'
import Autosuggest from 'react-autosuggest'
import PropTypes from "prop-types"

function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}


function getSuggestionValue(suggestion) {
  return suggestion;
}

function shouldRenderSuggestions() {
  return true;
}

function renderSuggestion(suggestion) {
  return (
    <span>{suggestion}</span>
  );
}

class ASWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      suggestions: []
    }

    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this)
    this.reset = this.reset.bind(this)
  }

  reset() {
    this.setState({
      value: '',
    })
  }

  getSuggestions(value) {
    const escapedValue = escapeRegexCharacters(value.trim());
    const regex = new RegExp('^' + escapedValue, 'i');
    const maxSuggestions = this.props.maxSuggestions ? this.props.maxSuggestions : 12

    return this.props.suggestions.filter(sug => regex.test(sug)).slice(0, maxSuggestions);
  }

  onChange = (event, {newValue, method}) => {
    // this.setState({
    //   value: newValue
    // });
    this.props.onChange(this.props.name, newValue)
  };

  onSuggestionsFetchRequested = ({value}) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const {suggestions} = this.state
    const {fieldValue} = this.props
    const inputProps = {
      className: "form-control",
      placeholder: this.props.placeholder,
      value: fieldValue,
      onChange: this.onChange
    };

    const renderInputComponent = inputProps => {
      const required = this.props.hasOwnProperty('required') ? this.props.required : true
      return (
        <div>
          <input {...inputProps} required={required}/>
        </div>
      )
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        shouldRenderSuggestions={shouldRenderSuggestions}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        renderInputComponent={renderInputComponent}
      />
    );
  }
}

ASWrapper.propTypes = {
  placeholder: PropTypes.string.isRequired,
  suggestions: PropTypes.array.isRequired,
  required: PropTypes.bool
}

export default ASWrapper
