import axios from 'axios';
import {API_URL} from '../config'

import {tokenConfig} from "./authActions"
import {pushToast} from "./toastActions"
import {GAME_LOADED, GAME_SET_NAME} from "./types";
// import {loadItems} from "./shoppingActions";

// Add new item
export const resetGame = () => (dispatch, getState) => {
  const body = {}
  axios
    .post(`${API_URL}/api/wavelength/game`, body, tokenConfig(getState))
    .then(res => {
      dispatch(loadGame())
      dispatch(pushToast("Game Reset", "The Game was successfully reset!"))
    })
    .catch(err => {
      console.log(err)
      dispatch(pushToast(
        "Game Error",
        "There was an error resetting the game!"
      ))
    });
};

export const loadGame = () => (dispatch, getState) => {
  axios
    .get(`${API_URL}/api/wavelength/game`, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GAME_LOADED,
        payload: res.data
      })
    })
    .catch(err => {
      console.log(err)
    });
};

export const setName = (name) => (dispatch) => {
  dispatch({
    type: GAME_SET_NAME,
    payload: name
  })
  dispatch(registerName(name))
}

export const registerName = (name) => (dispatch, getState) => {
  const body = {action: 'ADD_PLAYER', payload: {name}}
  axios
    .patch(`${API_URL}/api/wavelength/game`, body, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: GAME_LOADED,
        payload: res.data.result
      })
    })
    .catch(err => {
      console.log(err)
    });
};

export const submitScore = (name, score) => (dispatch, getState) => {
  const body = {action: 'SUBMIT_SCORE', payload: {name, score}}
  axios
    .patch(`${API_URL}/api/wavelength/game`, body, tokenConfig(getState))
    .then(res => {
      console.log(res.data)
      dispatch({
        type: GAME_LOADED,
        payload: res.data.result
      })
    })
    .catch(err => {
      console.log(err)
    });
};

export const nextRound = (name) => (dispatch, getState) => {
  const body = {action: 'NEXT_ROUND', payload: {name}}
  axios
    .patch(`${API_URL}/api/wavelength/game`, body, tokenConfig(getState))
    .then(res => {
      console.log(res.data)
      dispatch({
        type: GAME_LOADED,
        payload: res.data.result
      })
    })
    .catch(err => {
      console.log(err)
    });
};