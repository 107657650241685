import {ITEMS_ARCHIVED, ITEMS_ARCHIVING, ITEMS_LOADED,} from '../actions/types';


const initialState = {
  active: [],
  archiving: false
};

export default function(state = initialState, action) {

  switch (action.type) {
    case ITEMS_LOADED:
      return {
        ...state,
        active: [ ...action.payload]
      }
    case ITEMS_ARCHIVING:
      return {
        ...state,
        archiving: true
      }
    case ITEMS_ARCHIVED:
      return {
        ...state,
        archiving: false
      }
    default:
      return state;
  }
}
