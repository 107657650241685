export const USER_LOADING = "USER_LOADING"
export const USER_LOADED = "USER_LOADED"
export const AUTH_ERROR = "AUTH_ERROR"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL = "LOGIN_FAIL"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"

export const WG_USERS_LOADED = "WG_USERS_LOADED"

// export const TRANSACTION_SUCCESS = "TRANSACTION_SUCCESS"
export const TRANSACTIONS_LOADED = "TRANSACTIONS_LOADED"

export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'

export const ITEMS_ARCHIVING = 'ITEMS_ARCHIVING'
export const ITEMS_ARCHIVED = 'ITEMS_ARCHIVED'
export const ITEMS_LOADED = 'ITEMS_LOADED'

export const PUSH_TOAST = 'PUSH_TOAST'
export const TOGGLE_TOAST = 'TOGGLE_TOAST'
export const REMOVE_TOAST = 'REMVOE_TOAST'

export const GAME_LOADED = 'GAME_LOADED'
export const GAME_SET_NAME = 'GAME_SET_NAME'