import {combineReducers} from 'redux';
import authReducer from "./authReducer"
import modalReducer from "./modalReducer"
import financeReducer from "./financeReducer"
import generalReducer from "./generalReducer"
import toastReducer from "./toastReducer"
import shoppingReducer from "./shoppingReducer"
import wavelengthReducer from "./wavelengthReducer";

export default combineReducers({
  auth: authReducer,
  modal: modalReducer,
  finance: financeReducer,
  general: generalReducer,
  toasts: toastReducer,
  items: shoppingReducer,
  game: wavelengthReducer,
});
